<template>
  <div class="animated fadeIn">
    <!-- Basic Information -->
    <b-card-header>
      Basic Information
      <div class="card-header-actions">
        <a class="card-header-action" href="addons" rel="noreferrer noopener">
          <small class="text-muted">Back</small>
        </a>
      </div>
    </b-card-header>
    <b-card>
      <b-row class="mb-4">
        <b-col sm="4">
          <h6 class="title-content">Last Update</h6>
          <p class="content-text">{{ this.data.basic_information.last_update }}</p>
        </b-col>
        <b-col sm="4">
          <h6 class="title-content">Add On Name</h6>
          <p class="content-text">{{ this.data.basic_information.add_on_name }}</p>
        </b-col>
        <b-col sm="4">
          <h6 class="title-content">Add On Code</h6>
          <p class="content-text">{{ this.data.basic_information.add_on_code }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="4">
          <h6 class="title-content">Period</h6>
          <p class="content-text">{{ this.data.basic_information.period }}</p>
        </b-col>
        <b-col sm="4">
          <h6 class="title-content">Amount</h6>
          <p class="content-text">{{ this.data.basic_information.amount }}</p>
        </b-col>
        <b-col sm="4">
          <h6 class="title-content">Status</h6>
          <p :class="this.data.basic_information.status === 'Active' ? 'content-text text-success' : 'content-text text-danger'">
            {{ this.data.basic_information.status }}
          </p>
        </b-col>
      </b-row>
    </b-card>
    <!-- Detail Information -->
    <b-card-header>
      Detail Information
    </b-card-header>
    <b-card>
      <b-row class="mb-4">
        <b-col sm="4">
          <h6 class="title-content">Date Created</h6>
          <p class="content-text">{{ this.data.detail_information.created_date }}</p>
        </b-col>
        <b-col sm="4">
          <h6 class="title-content">Bundle</h6>
          <p class="content-text">{{ this.data.detail_information.is_bundle }}</p>
        </b-col>
        <b-col sm="4">
          <h6 class="title-content">Max Bundle</h6>
          <p class="content-text">{{ this.data.detail_information.max_bundle }}</p>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="4">
          <h6 class="title-content">Priority Ordering</h6>
          <p class="content-text">{{ this.data.detail_information.ordering }}</p>
        </b-col>
        <b-col sm="4">
          <h6 class="title-content">Data Type</h6>
          <p class="content-text">{{ this.data.detail_information.data_type }}</p>
        </b-col>
        <b-col sm="4">
          <h6 class="title-content">Transaction Type</h6>
          <p class="content-text">{{ this.data.detail_information.transaction_type }}</p>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="4">
          <h6 class="title-content">Tax Type</h6>
          <p class="content-text">{{ this.data.detail_information.tax_type }}</p>
        </b-col>
        <b-col sm="4">
          <h6 class="title-content">Tax Amount</h6>
          <p class="content-text">{{ this.data.detail_information.tax_amount }}</p>
        </b-col>
        <b-col sm="4">
          <h6 class="title-content">Discount Amount</h6>
          <p class="content-text">{{ this.data.detail_information.discount_amount }}</p>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="4">
          <h6 class="title-content">Feature</h6>
          <p class="content-text">{{ this.data.detail_information.feature_name }}</p>
        </b-col>
        <b-col sm="4">
          <h6 class="title-content">Continuity</h6>
          <p class="content-text">{{ this.data.detail_information.continuity }}</p>
        </b-col>
        <b-col sm="4">
          <h6 class="title-content">URL Logo Add Ons</h6>
          <p class="content-text">{{ this.data.detail_information.url_logo }}</p>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col sm="4">
          <h6 class="title-content">URL Detail Add Ons</h6>
          <p class="content-text">{{ this.data.detail_information.url_detail }}</p>
        </b-col>
        <b-col sm="4">
          <h6 class="title-content">Description</h6>
          <p class="content-text">{{ this.data.detail_information.description }}</p>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from  'axios'

export default {
  name: 'detail-transaction-addons',
  components: {
  },
  data() {
    return {
      data: {},
      isLoading: false,
      errors: {
        code: '',
        message: '',
        status: ''
      },
      busy:false,
    }
  },
  created () {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
    this.onFetchDetail()
  },
  methods: {
    async onFetchDetail() {
      this.isLoading = true;
      await this.$http.get(`addons/setup/detail?id=` + atob(this.$route.params.id))
        .then((result) => {
          this.isLoading = false;
          this.data = result.data.data;
        }).catch((error) => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.message = error.response.data.meta.message;
            this.errors.status = error.response.data.meta.code;
          }
        })
    },
  }
}
</script>

<style>
  .title-content{
    color: #606060;
  }
  .content-text{
    font-weight: bold!important;
  }
  .error-input .dropdown-toggle{
    border-color: red!important;
  }
  .error-message{
    color: #FA836B;
  }
  .text-red {
    color: #CB211F;
  }
  .text-success {
    color: #35AA4B;
  }

  /* Absolute Center Spinner */
  .loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  /* Transparent Overlay */
  .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
  }
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
